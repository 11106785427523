
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import useJournalEntryType from "@/core/services/compositions/enum/useJournalEntryType";

interface Filter {
  date: string;
}

export default defineComponent({
  name: "filter-invocation",
  components: {},
  setup(props, context) {
    const { query } = useQuery();
    const { channel } = useChannelByHash();
    const { journalEntryType } = useJournalEntryType();


    const data = reactive<Filter>({
      date: query.value.date || '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.date = '';
    };

    return {
      data,
      filter,
      reset,
      channel,
      journalEntryType
    };
  }
});
