
import { defineComponent, ref, computed, watch, reactive, onMounted } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { MenuComponent } from "@/assets/ts/components";
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import useJournalEntryFiles from "@/core/services/compositions/reconcile/useJournalEntryFiles";
import { ElNotification } from 'element-plus'
import useDownload from "@/core/helpers/download";
import ApiService from "@/core/services/ApiService";
import {
  showErrorPopup,
  showSuccessPopup,
} from "@/core/helpers/common-helper";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import { checkPermission } from '@/router/authentication'
import FilterRecon from "./filter-recon.vue";
import moment from 'moment';

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterRecon
  },
  setup() {
    // init
    const { t } = useI18n();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const paginationRef = ref(null);
    const { query } = useQuery();
    const router = useRouter();
    const dialogVisible = ref(false);
    const fileImport = ref(null);
    const { channel } = useChannelByHash();
    const hashChannel = ref(null);

    // breadcrumb
    setNewPageBreadcrumbs(t("reconcile.journal.file.breadcrumb"), [{ title: t("reconcile.journal.file.title") }]);

    const form = reactive({
      date: '',
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      date: query.value.date || form.date,
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useJournalEntryFiles(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const filtered = (filters) => {
      MenuComponent.hideDropdowns(undefined);
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        date: form.date,
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const { handleDownloadToFile, isDownloading } = useDownload();

    const download = (file) => {
      handleDownloadToFile(`/app/reconcile/journal-entry/bmi/${file}`, file);
    }

    const showDialog = (file) => {
      dialogVisible.value = true;
      fileImport.value = file;
    }

    const convert = () => {
      if (hashChannel.value == null) {
        return;
      }

      dialogVisible.value = false;
      isValidating.value = true;
      const payload = {
        filename: fileImport.value,
        channel_id: hashChannel.value
      };

      ApiService.setHeader();
      ApiService.put(
        `app/reconcile/journal-entry/convert`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Import Journal Entry',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");

          isValidating.value = false;
          hashChannel.value = null;
        });
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      isValidating,
      data,
      paginationRef,
      handleChangePage,
      download,
      isDownloading,
      submitButton,
      filtered,
      convert,
      dialogVisible,
      showDialog,
      channel,
      hashChannel,
      checkPermission
    };
  },
});
